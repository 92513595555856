import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import logo from '../nano-logo.png';
import mobileMenuPic from '../mobile_menu.png';
import '../../styles/header.css';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

function DeleteUser(props) {
    const [showConfirm, setConfirm] = useState(false);
    
    return (
        <Button style={{margin: "10px"}} fluid onClick={()=> setConfirm(!showConfirm)} size="big" color="red">
            Delete User
            <Confirm
                open={showConfirm}
                content='Are you sure you want to delete this user?'
                confirmButton="Yes, Thanos this dude"
                onCancel={()=> setConfirm(!showConfirm)}
                onConfirm={()=> {
                    setConfirm(!showConfirm);
                    props.closeModal();
                    deleteUser(props.id).then(()=> props.refreshUsers())
                }}
                size='mini'
            />
        </Button>
    );
};

const deleteUser = async (id) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/users/delete/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return store.addNotification({
            title: "Success:",
            message: `${id} successfully deleted.`,
            type: "success",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    } else {
        return store.addNotification({
            title: "Error:",
            message: JSON.stringify(response.error),
            type: "danger",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    }
};

export default DeleteUser;