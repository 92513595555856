import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown, Grid } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import '../../styles/header.css';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

function EditCoupon(props) {
    const [open, setModal] = useState(false);
    const [couponForm, setCouponForm] = useState(props.coupon);
    const [products, setProducts] = useState([]);
    useEffect(()=> {
        getProducts().then(products => setProducts(products));
    }, []);

    console.log(couponForm)
    return (
        <Modal size="large" closeIcon open={open} onClose={()=> setModal(false)} trigger={<Icon size="large" style={{margin:"10px"}} link onClick={()=> setModal(true)} name="edit" />}>
            <Modal.Header>Edit Coupon:</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                            <Container>
                                <Grid>
                                    <Grid.Column width={8}>
                                        {
                                            Object.entries(couponForm).map((property)=> {
                                                if (property[0] === '_id') return null;
                                                if (property[0] === '__v') return null;
                                                if (property[0] === 'date') return null;
                                                if (property[0] === 'dateCreated') return null;
                                                if (property[0] === 'products') {
                                                    return (
                                                        <Dropdown style={{margin:"10px"}} onChange={(e, {value})=> setCouponForm({...couponForm, products: value})} placeholder='Products' multiple selection options={products.map(product => {
                                                            return { key: product.name, text: product.name, value: product }
                                                        })} 
                                                    />
                                                    )
                                                }
                                                return (
                                                    
                                                    <Input defaultValue={property[1]} style={{margin: "10px"}} label={property[0]} onChange={(e)=> {
                                                        
                                                        setCouponForm({...couponForm, [property[0]]: (!isNaN(e.target.value) ? parseFloat(e.target.value) : e.target.value) })}
                                                    } />
                                                )
                                            })
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <pre>{JSON.stringify(couponForm, null, 2)}</pre>
                                    </Grid.Column>
                                </Grid>
                                <Button onClick={()=> {
                                        editCoupon(couponForm).then(()=> props.refreshCoupons());
                                        setModal(false)
                                        }} style={{marginTop:"25px"}} fluid>
                                    Save Changes
                                </Button>
                            </Container>
                    </Modal.Description> 
                </Modal.Content>
        </Modal>
    );
};

const editCoupon = async (coupon) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/coupons/edit/${coupon._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            id: coupon._id,
            coupon: coupon
        })
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return store.addNotification({
            title: "Success:",
            message: `Coupon successfully edited.`,
            type: "success",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    } else {
        return store.addNotification({
            title: "Error:",
            message: JSON.stringify(response.error),
            type: "danger",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    }
};

const getProducts = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/products', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};

export default EditCoupon;