import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGa from 'react-ga';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Home from './pages/Home';
import DatacenterPricing from './pages/DatacenterPricing';
import Contact from './pages/Contact';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';
import Header from './components/Header';
import DatacenterPortal from './pages/DatacenterPortal';
import Logout from './pages/Logout';
import Login from './pages/Login';
import AdminPanel from './pages/admin/AdminPanel';
import DashboardNav from './components/DashboardNav';
import ConfirmSignUp from './pages/ConfirmSignUp';
import AdminPanelNav from './components/admin/AdminPanelNav';
import Users from './pages/admin/Users';
import Coupons from './pages/admin/Coupons';
import Discord from './pages/admin/Discord';
import Proxies from './pages/admin/Proxies';
import Products from './pages/admin/Products';
import Settings from './pages/admin/Settings';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Success from './pages/Success';

import { checkAuth } from './api/auth';
import { setUserData } from "./store/action/action";

function App(props) {
  const noAuthRoutes = ['/login', '/logout', '/register', '/reset-password', '/register/confirmation/', '/pricing/datacenter'];
  console.log(props)
  useEffect(()=> {
    const isNoAuthRoute = noAuthRoutes.includes(window.location.pathname);
    console.log(isNoAuthRoute)
  
    if (!isNoAuthRoute) {
      checkAuth().then(response => response.success ? props.setUserData(response.success.user) : null)
    }
    
    if (process.env.NODE_ENV === 'production') {
      ReactGa.initialize('UA-164639851-1');
      ReactGa.pageview(window.location.pathname + window.location.search);
    } else {
      console.log('Running local env.');
    };
  }, []);
  
  return (
    <div>
      <Router>
      <ReactNotification />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/admin" component={AdminDashboard} />
      <Route path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/order/success" component={Success} />
          <Header />
            <Switch>
              {/* <Route exact path="/" component={Home} /> */}
              <Route path="/logout" component={Logout} />
              {/* <Route path="/orders" component={Orders} /> */}
              <Route exact path="/pricing/datacenter" component={DatacenterPricing} />
              <Route path="/contact" component={Contact} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy}/>
              <Route path="/faq" component={Faq} />
              <Route path="/register/confirmation" component={ConfirmSignUp} />
              {/* <Route component={() => "404 NOT FOUND"} />  */}
            </Switch>
        </Router>
    </div> 
  )
};

const Dashboard = (props) => {
  return (
    <div>
      <DashboardNav />
      <Switch>
        <Route path="/dashboard/proxies" component={DatacenterPortal} /> 
      </Switch>
    </div>
  )
};

const AdminDashboard = (props) => {
  return (
    <div>
      <AdminPanelNav />
      <Switch>
        <Route exact path="/admin" component={AdminPanel} />
        <Route path="/admin/users" component={Users} />
        <Route path="/admin/coupons" component={Coupons} />
        <Route path="/admin/products" component={Products} />
        <Route path="/admin/discord" component={Discord} />
        <Route path="/admin/proxies" component={Proxies} />
        <Route path="/admin/settings" component={Settings} />
      </Switch>
    </div>
  )
};

const mapStateToProps = state => {
  return { status: state.status }
};

const mapDispatchToProps = dispatch => {
  return {
      setUserData: (user) => dispatch(setUserData(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
