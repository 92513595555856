import React, { Component } from 'react'
import { Container, Button, Header, Grid, Image, Divider, Icon } from 'semantic-ui-react'
import '../styles/Contact.css';

export default class Contact extends Component {
    render() {
        return (
            <div className="contact-page">
           
           <div className="title-content">
               <Container textAlign="center">
                   <Header className="title" size='huge'>Contact Us</Header>
                   <Divider />
                   <p class="contact-p">Feel free to reach out via email or Discord to get support. We respond faster via Discord.</p>
                   <div class="contact-methods">
                        <Grid columns={2} divided inverted centered stackable>
                            <Grid.Row>
                                <Grid.Column width={5} textAlign="center">
                                    <Icon 
                                        size="massive" 
                                        style={{margin:"10px", display: "inline", textShadow: "1px 1px 10px rgb(0,0,0)"}} 
                                        name='discord' 
                                        link 
                                        onClick={()=> window.open("https://discord.com/invite/h8fTByA")} 
                                    />
                                </Grid.Column>
                                <Grid.Column width={5} textAlign="center">
                                    <Icon 
                                        size="massive" 
                                        style={{margin:"10px", display: "inline", textShadow: "1px 1px 10px rgb(0,0,0)"}} 
                                        name='mail' 
                                        link 
                                        onClick={()=> window.open("mailto:support@nanoproxies.io")} 
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                   </div>
               </Container>
           </div>
           
           <Container style={{marginTop:"200px"}}>
               
           </Container>
       </div>
        )
    }
}