import React, { useState, useEffect } from 'react'
import { Container, Image, Button, Icon, Input, Menu, Grid} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { resetPassword, resetPasswordRequest } from '../api/auth';
import { notify } from '../utils/notification';
import logo from '../components/nano-logo.png';
import '../styles/Login.css';

const queryString = require('query-string');

function ResetPassword(props) {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState({show: false, token: null});

    useEffect(()=> {
        const token = queryString.parse(props.location.search);
        if (token.code) return setCode({ show: true, token: token.code });
    }, []);

    const submitPasswordReset = async () => {
        setLoading(true);

        if (!email) {
            return notify('warning', 'Missing Fields', 'Please insert a valid email.');
        };

        const response = await resetPasswordRequest(email);
        setLoading(false);

        if (response.success) {
            return notify('success', 'Success', "Please check your email.");
        }
    };

    const confirmPasswordReset = async () => {
        setLoading(true);

        if (!code || !password) {
            return notify('warning', 'Error', "Please fill out all fields.");
        };

        const response = await resetPassword(code.token, password);
        setLoading(false);

        if (response.success) {
            notify('success', 'Success', JSON.stringify(response.success));
            
            return setTimeout(()=> {return window.location = '/login'}, 2000)
        } else {
            return notify('danger', 'Error', JSON.stringify(response.error));
        }
    };

    return (
        <div className="body">
            {
                !code.show ?
                <Container textAlign="center">
                    <div style={{marginTop:"50px"}}>
                        <Image centered size="tiny" src={logo} />
                        <Container textAlign="center" style={{marginTop:"30px"}}>
                            <h3 class="fields">Email Address</h3>
                            <Input type="email" size="small" placeholder="Email Address" onChange={(e)=> setEmail(e.target.value)} />
                        </Container>
                        <Container style={{width:"575px", marginTop: "15px"}}>
                            <Button loading={loading} className="addUser" inverted color="green" onClick={()=> submitPasswordReset()}>
                                <text class="buttonText">Email Reset Link</text>
                                <Icon name="mail outline"/>
                            </Button> <br />
                            <Button className="forgotPassword" inverted color="red" as={ Link } to='/login' style={{marginTop: "10px"}}>
                                <text class="buttonText">Cancel</text>
                                <Icon name="cancel"/>
                            </Button>
                        </Container>
                    </div>
                </Container>
            :   <Container textAlign="center">
                    <div style={{marginTop:"170px"}}>
                        <Image centered size="medium" src={logo} />
                        <Container textAlign="center" style={{marginTop:"50px", width:"575px"}}>
                            <h3 class="fields">New Password</h3>
                            <Input type="password" size="large" fluid placeholder="Password" onChange={(e)=> setPassword(e.target.value)} />
                        </Container>
                        <Container style={{width:"575px", marginTop: "15px"}}>
                            <Menu secondary compact stackable widths="1">
                                    <Menu.Item>
                                        <Button loading={loading} fluid className="addUser" inverted color="green" onClick={()=> confirmPasswordReset()}>
                                            <text class="buttonText">Confirm Password Change</text>
                                            <Icon name="mail outline"/>
                                        </Button>
                                    </Menu.Item>
                            </Menu>
                        </Container>
                    </div>
                </Container>
            }
        </div>
    )
};

export default ResetPassword;