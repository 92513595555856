import React, { useState } from 'react';
import { Container, Image, Button, Icon, Input, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { registerUser } from '../api/auth';
import { notify } from '../utils/notification';
import logo from '../components/nano-logo.png';
import '../styles/Login.css';

function Register() {
    const [registerForm, setRegisterForm] = useState({email: null, firstPassword: null, secondPassword: null});
    const [loading, setLoading] = useState(false);

    const submitRegistration = async () => {
        setLoading(true);
        const { email, firstPassword, secondPassword } = registerForm;

        try {
            if (!email || !firstPassword || !secondPassword) throw ('Please fill out all fields.');
            if (!email.includes('@') || !email.includes('.com')) throw ('Please insert a valid email.');
            if (firstPassword !== secondPassword) throw ('Passwords do not match.');
            if (!firstPassword || !secondPassword) throw ('Please fill out all fields.')
        } catch (error) {
          console.log(error)
          return notify('warning', 'Missing Fields', error.toString());
        };

        const response = await registerUser(email, firstPassword);
        setLoading(false);
        if (response.success) {
            notify('success', 'Success', "Please check your email to confirm registration.");
            return setTimeout(()=> {
                return window.location = '/login';
            }, 5000)
        } else {
            return notify('danger', 'Error', JSON.stringify(response.error));
        }
    };

    return (
        <Container textAlign="center">
            <div className="body">
                <Image centered size="tiny" src={logo} />
                <Container textAlign="center" style={{marginTop:"50px", width:"575px"}}>
                    <h3 class="fields">Email Address</h3>
                    <Input type="email" size="small" placeholder="Email Address" onChange={(e)=> setRegisterForm({...registerForm, email: e.target.value})} />
                    <h3 class="fields">Password</h3>
                    <Input type="password" size="small" placeholder="Password" onChange={(e)=> setRegisterForm({...registerForm, firstPassword: e.target.value})}/>
                    <h3 class="fields">Verify Password</h3>
                    <Input type="password" size="small" placeholder="Password" onChange={(e)=> setRegisterForm({...registerForm, secondPassword: e.target.value})}/>
                </Container>
                <Container style={{width:"575px", marginTop: "15px"}}>
                    <Menu secondary compact stackable widths="2">
                            <Menu.Item>
                                <Button loading={loading} className="addUser" inverted color="green" onClick={()=> submitRegistration()}>
                                    <text class="buttonText">Register</text>
                                    <Icon name="add user"/>
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button className="forgotPassword" inverted color="red" as={ Link } to='/login'>
                                    <text class="buttonText">Cancel</text>
                                    <Icon name="cancel"/>
                                </Button>
                            </Menu.Item>
                    </Menu>
                </Container>
            </div>
        </Container>
    )
};

export default Register;