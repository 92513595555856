import React, { useEffect, useState } from 'react'
import { Container, Image, Input, Menu, Table, Modal, Portal, Button, Header, Dropdown, Search, Segment, Icon, Divider} from 'semantic-ui-react';
import CreateCoupon from '../../components/admin/CreateCoupon';
import DeleteCoupon from '../../components/admin/DeleteCoupon';
import EditCoupon from '../../components/admin/EditCoupon';

function Coupons(props) {
    const [coupons, setCoupons] = useState([]);
    
    useEffect(()=> {
        getCoupons().then(coupons => setCoupons(coupons));
    }, []);

    return (
        <Container>
            <CreateCoupon refreshCoupons={()=> getCoupons().then(coupons => setCoupons(coupons))} />
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Code</Table.HeaderCell>
                        <Table.HeaderCell>Percentage</Table.HeaderCell>
                        <Table.HeaderCell>Products</Table.HeaderCell>
                        <Table.HeaderCell>Uses</Table.HeaderCell>
                        <Table.HeaderCell>Expires</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {coupons.length === 0 ? null : coupons.map((coupon)=> { 
                        return (
                            <Table.Row>                                       
                                <Table.Cell>{coupon.code}</Table.Cell>
                                <Table.Cell>{coupon.percentage}</Table.Cell>
                                <Table.Cell>
                                    {coupon.products.map((product)=> <li>{product.name}</li>)}
                                </Table.Cell>
                                <Table.Cell>{`${coupon.currentUses} / ${coupon.maxUses}`}</Table.Cell>
                                <Table.Cell>{new Date(coupon.expiration * 1000).toLocaleString()}</Table.Cell>
                                <Table.Cell collapsing>
                                    <EditCoupon coupon={coupon} refreshCoupons={()=> getCoupons().then(coupons => setCoupons(coupons))}/>
                                    <DeleteCoupon id={coupon._id} refreshCoupons={()=> getCoupons().then(coupons => setCoupons(coupons))}/>
                                    {/* <Button icon="close icon" onClick={()=>{this.deleteUser(user.discordId)}}/>                                */}
                                </Table.Cell>
                            </Table.Row>
                            );                                 
                        })
                    }                    
                </Table.Body>
            </Table>
        </Container>
    )
};

export default Coupons;

const getCoupons = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/coupons', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};