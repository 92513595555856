import React, { useEffect, useState } from 'react'
import { Container, Image, Input, Menu, Table, Modal, Portal, Button, Header, Dropdown, Search, Segment, Icon, Divider} from 'semantic-ui-react';

function Discord(props) {
    let user = localStorage.getItem('user');
    let discordUser = localStorage.getItem('discordUser');
    user ? user = JSON.parse(user) : discordUser = JSON.parse(discordUser);

    return (
        <Container>
           Discord page
        </Container>
    )
};

export default Discord;