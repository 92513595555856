import React, { Component } from 'react'
import { Container, Header, Button, Icon, Popup, Input, Modal, Label } from 'semantic-ui-react';
import ClipboardJS from "clipboard";

import { notify } from '../utils/notification';
import { whitelistIp } from '../api/proxies';
new ClipboardJS(".copyProxyList");

export default class DatacenterPackageEditModal extends Component {
    state = { open: false, addIpModalOpen: false, ip: this.props.userIp, whitelistedIps: this.props.whitelistedIps, token: this.props.token, proxies: this.props.proxies };

    open = () => {
      return this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false });
    };

    addIp = async () => {
        let numOfWhitelistedIps = this.state.whitelistedIps.length;
        if (numOfWhitelistedIps >= 5) {
            return notify('danger', "Error", 'Maximum amount of whitelisted IPs allowed reached.')
        };
        const ipAlreadyExist = await this.state.whitelistedIps.filter(ip => this.state.ip === ip);
        let includesColon = this.state.ip.match("[,:-]+");
        if (includesColon) {
            return notify('warning', "Error", 'Please do not insert ports, just an IP address.');
        };
        
        if (ipAlreadyExist.length !== 0) {
            return notify('warning', "Error", 'IP already whitelisted.');
        };
        
        let updatedWhitelistList = [...this.state.whitelistedIps, this.state.ip];
        
        return this.setState({...this.state, whitelistedIps: updatedWhitelistList, addIpModalOpen: false});
    };

    removeIp = async (ipToDelete) => {
        
        const updatedWhitelistList = await this.state.whitelistedIps.filter(ip => ip !== ipToDelete);
        
        return this.setState({...this.state, whitelistedIps: updatedWhitelistList})
    };

    whitelistIps = async () => {
        this.setState({open: false})
        const response = await whitelistIp(this.state.token, this.state.whitelistedIps)

        if (response.status === 'success') {
            return notify('success', "Success", `Whitelist successfully updated`);
        } else {
            return notify('danger', "Error", JSON.stringify(response));
        };
    };

    render() {
        console.log(this.state)
        return (
            <Modal open={this.state.open} onClose={this.close} trigger={<Button primary onClick={this.open} >Configure</Button>} dimmer="true">
                <Modal.Header>Configure Proxies</Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Header>Your Proxies: </Header>
                                <Container style={{textAlign: "center"}}>
                                    {this.props.proxies.map((proxy)=> {
                                        return (
                                            <Label color="blue" size="large" style={{margin:"15px"}}>
                                              {proxy}
                                            </Label>
                                        )
                                    })}
                                </Container>
                        </Modal.Description>
                        
                        <Header>Whitelisted IPs:</Header>
                        <Container>
                            {this.state.whitelistedIps.map((ip)=> {
                                return (
                                    <Label size="large" style={{margin:"15px", verticalAlign:"middle"}}>
                                        {ip} <Icon color="red" name="delete" onClick={()=> this.removeIp(ip)} />
                                    </Label>
                                )
                            })}
                            
                            <Modal closeIcon open={this.state.addIpModalOpen} onClose={() => this.setState({addIpModalOpen: false})} size="tiny" trigger={
                                    this.state.whitelistedIps.length >= 5 ? null 
                                    : <Button 
                                        onClick={()=> this.setState({...this.state, addIpModalOpen: true})}
                                        style={{verticalAlign:"middle"}} 
                                        primary icon={"add"} 
                                    >
                                    </Button>
                                }
                            >
                                <Modal.Header>Add IPv4</Modal.Header>
                                <Modal.Content>
                                    <Input 
                                        fluid 
                                        action={
                                            <Button onClick={this.addIp}>
                                            Add IP
                                            </Button>
                                        } 
                                        defaultValue={
                                            this.state.whitelistedIps.includes(this.props.userIp) 
                                            ? null 
                                            : this.props.userIp
                                        } 
                                        placeholder='example: 171.22.139.114'
                                        onChange={(e)=> this.setState({ ...this.state, ip: e.target.value.toString() })}
                                        
                                    />
                                </Modal.Content>
                            </Modal>
                        </Container>          
                    </Modal.Content>
                <Modal.Actions>
                <div style={{textAlign:"center"}}>
                    <Button className="copyProxyList" style={{margin:"15px"}} color='blue' data-clipboard-text={(()=> {
                        let clipboardText = "";
                        this.state.proxies.map((proxy)=> {
                          if (proxy == this.state.proxies[this.state.proxies.length - 1]) {
                            clipboardText += proxy;
                          } else {
                            clipboardText += proxy + '\n';
                          }                   
                        });
                        return clipboardText;
                        })()}
                    >
                        <Icon name='copy' /> Copy Proxy List
                    </Button>
                    <Button style={{margin:"15px"}} onClick={this.close} color='red'>
                        <Icon name='remove' /> Cancel Edits
                    </Button>
                    <Button style={{margin:"15px"}} color='green' onClick={this.whitelistIps}>
                        <Icon name='checkmark' /> Save Configuration
                    </Button>
                </div>
                </Modal.Actions>
            </Modal>
        )
    }
};
