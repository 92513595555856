import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Button} from 'semantic-ui-react';
import { createCheckoutSession } from '../../api/payment';
import { notify } from '../../utils/notification';
import { getDatacenterStock, isStockAvailable } from '../../api/proxies';

const stripePromise = loadStripe('pk_test_G4VlAYoFePjg7N7H78H9ohVr');

function DatacenterCheckout(props) {

    const handleClick = async () => {

        if (!props.status.user) return notify('danger', "Error", "Please sign-in first.");

        const stockAvailable = await getDatacenterStock()
            .then(stock => {
                const inStock = isStockAvailable(stock, props.servername);
                return inStock;
            });
            
        if (!stockAvailable) return notify('danger', "Error", "No more stock available.");

        var stripe = await stripePromise;
        
        createCheckoutSession(props.priceId, window.location.href, props.numOfProxies)
            .then(data => {
                stripe.redirectToCheckout({sessionId: data.sessionId})
                    .then(handleResult);
            })
    };

    const handleResult = (result) => {
        if (result.error) {
          return notify('danger', "Error", result.error);
        }
    };
    
    return (
        <Button fluid id="checkout" onClick={handleClick} active={props.available} disabled={!props.available}>{props.available ? 'Subscribe' : 'Sold Out'}</Button>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default connect(mapStateToProps)(DatacenterCheckout);
