import React, { useEffect, useState } from 'react'
import { Container, Image, Input, Menu, Table, Modal, Portal, Button, Header, Dropdown, Search, Segment, Icon, Divider} from 'semantic-ui-react';
import CreateProduct from '../../components/admin/CreateProduct';
import EditProduct from '../../components/admin/EditProduct';

function Products(props) {
    const [products, setProducts] = useState(null);
    
    useEffect(()=> {
        getProducts().then(products => setProducts(products));
    }, []);

    return (
        <Container>
            <CreateProduct />
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>Min Price</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Available</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {!products ? null : products.map((product)=> { 
                        return (
                            <Table.Row>                                       
                                <Table.Cell>{product.name}</Table.Cell>
                                <Table.Cell>{product.category}</Table.Cell>
                                <Table.Cell>{product.location}</Table.Cell>
                                <Table.Cell>{product.minPrice}</Table.Cell>
                                <Table.Cell>{product.price}</Table.Cell>
                                <Table.Cell>{product.available.toString()}</Table.Cell>
                                <Table.Cell collapsing>
                                    <EditProduct product={product} refreshProducts={()=> getProducts().then(products => setProducts(products))}/>
                                    {/* <Button icon="close icon" onClick={()=>{this.deleteUser(user.discordId)}}/>                                */}
                                </Table.Cell>
                            </Table.Row>
                            );                                 
                        })
                    }                    
                </Table.Body>
            </Table>
        </Container>
    )
};

export default Products;

const getProducts = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/products', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};