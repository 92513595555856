import React, { Component } from 'react'
require('dotenv').config()
export default class Logout extends Component {
    async componentDidMount() {
        localStorage.removeItem('user');

        const response = await fetch(process.env.REACT_APP_BACK_END + '/auth/logout', {
            method: 'GET',
            credentials: 'include'
          }).then(response => response.json())
        
        return window.location = process.env.REACT_APP_FRONT_END + '/login';
    };

    render() {
        return (
            <div>
                Logging out...
            </div>
        )
    }
};