import React, { useEffect, useState } from 'react'
import { Container, Table } from 'semantic-ui-react';
import AddAuthedIp from '../../components/admin/Settings/AddAuthedIp';
import DeleteAuthedIp from '../../components/admin/Settings/DeleteAuthedIp';

const { formatDate } =  require('../../utils/formatDate');

function Settings(props) {
    const [ips, setIps] = useState([]);
    
    useEffect(()=> {
        getAdminIps().then(ips => setIps(ips));
    }, []);

    return (
        <Container>
            <AddAuthedIp refreshAuthedIps={()=> getAdminIps().then(ips => setIps(ips))} />
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date Added</Table.HeaderCell>
                        <Table.HeaderCell>IP Address</Table.HeaderCell>
                        <Table.HeaderCell>Added By</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {ips.map((ip)=> { 
                        return (
                            <Table.Row>                                       
                                <Table.Cell>{formatDate(ip.date)}</Table.Cell>
                                <Table.Cell>{ip.ipAddress}</Table.Cell>
                                <Table.Cell>{ip.addedBy}</Table.Cell>
                                <DeleteAuthedIp id={ip._id} refreshAuthedIps={()=> getAdminIps().then(ips => setIps(ips))}/>
                            </Table.Row>
                            );                                 
                        })
                    }                    
                </Table.Body>
            </Table>
        </Container>
    )
};

export default Settings;

const getAdminIps = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/settings/authed-ips', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (!response.ok) throw response;
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};