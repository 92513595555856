import React, { useEffect, useState } from 'react'
import { Container, Header, Divider, Grid, Card, Loader} from 'semantic-ui-react';
import DatacenterPackage from '../components/Products/DatacenterPackage';
import { connect } from 'react-redux';
import '../styles/CardSectionStyles.css';
import '../styles/home.css';
import '../styles/datacenterPricing.css';
import { getDatacenterStock, isStockAvailable } from '../api/proxies';


function DatacenterPricing(props) {
    const [products, setProducts] = useState([]);
    const [proxyStock, setProxyStock] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        setLoading(true);
        getDatacenterProducts().then(products => {
            setProducts(products);
            setLoading(false);
        });
        
        getDatacenterStock().then(stock => {
            setProxyStock(stock);
        });
        
    }, []);
        
    return (
        <div className="home">
        <div className="title-content">
            <Container>
                <Header className="title" size='huge'>Datacenter Proxy Plans</Header>
                <Divider section/>
            </Container>
            
        </div>
        <div>
        <Loader active={loading} inverted />
        
            <Grid columns="equal" stackable style={{marginTop:"50px"}}>
                <Grid.Row centered>
                    <Header id="monthly-plans" textAlign="center" className="title" style={{color:"white", margin:"50px", fontSize:"30px"}}>Monthly Plans</Header>
                </Grid.Row>
                <Grid.Row centered>
                    <Card.Group stackable>
                        {
                            products.map((product)=> {
                                if (!product) return;
                                if (!product.hasOwnProperty('prices')) return;
                                if (!product.prices[0].hasOwnProperty('tiers')) return;

                                product.metadata.active = JSON.parse(product.metadata.active);

                                return product.prices.map(price => (
                                        <DatacenterPackage
                                            servername={product.metadata.servername}
                                            name={`${monthsToDays(price.recurring.interval_count)} Day ${product.name}`} 
                                            available={product.metadata.active && isStockAvailable(proxyStock, product.metadata.servername)} 
                                            productId={product.id}
                                            priceId={price.id}
                                            location={product.metadata.location} 
                                            tiers={price.tiers} 
                                            timeframe={'Monthly'} 
                                            image={product.images[0]}  
                                        />
                                    )
                                );
                            })
                        }
                    </Card.Group>
                </Grid.Row>
            </Grid>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default connect(mapStateToProps)(DatacenterPricing);

const getDatacenterProducts = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/product/list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({category: 'datacenter'})
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    
    return response;
};

const monthsToDays = (months) => {
    return months * 30;
};