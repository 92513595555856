import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Image, Button, Icon, Input, Menu } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { loginRequest, checkAuth } from '../api/auth';
import logo from '../components/nano-logo.png';
import '../styles/Login.css';
import { setUserData } from "../store/action/action";
import { notify } from '../utils/notification';

function Login(props) {
    const [loginForm, setLoginForm] = useState({email: null, password: null});
    const [redirect, setRedirect] = useState(false);

    useEffect(()=> {
        if (localStorage.getItem('user')) {
            checkAuth().then(res => {
                if (res.success) setRedirect(true)
                    else return;
            })
        }
    }, []);

    return (
        <Container textAlign="center">
            {redirect ? <Redirect push to="/" /> : null}
            <div className="body">
                <Image centered size="tiny" src={logo} />
                <Container textAlign="center" style={{marginTop:"30px", width:"575px"}}>
                    <h3 class="fields">Email Address</h3>
                    <Input autoFocus type="email" size="small" placeholder="Email Address" onChange={(e)=> setLoginForm({...loginForm, email: e.target.value})} />
                    <h3 class="fields">Password</h3>
                    <Input type="password" size="small" placeholder="Password" onChange={(e)=> setLoginForm({...loginForm, password: e.target.value})}/>
                </Container>
                <Container style={{width:"575px", marginTop: "15px"}}>
                    <Menu secondary compact stackable widths="3">
                            <Menu.Item className="login">
                                <Button className="login" inverted color="blue" onClick={()=> {
                                    if (loginForm.email === null || loginForm.password === null) return notify('warning', 'Missing Fields', 'Please fill out all required fields.');
                                    loginRequest(loginForm.email, loginForm.password).then(response=> {
                                        if (response.error) return notify('danger', 'Login Failed', 'Please try again or reset your password.');
                                        props.setUserData(response.success.user);
                                        return setRedirect(true)
                                    });
                                }}>
                                    <text class="buttonText">
                                        Login
                                    </text>
                                    <Icon name="sign-in"/>
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button className="addUser" inverted color="green" as={ Link } to='/register'>
                                    <text class="buttonText">Register</text>
                                    <Icon name="add user"/>
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button className="forgotPassword" inverted color="red" as={ Link } to='/reset-password'>
                                    <text class="buttonText">Reset Password</text>
                                    <Icon name="redo"/>
                                </Button>
                            </Menu.Item>
                    </Menu>
                </Container>
            </div>
        </Container>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setUserData: (user) => dispatch(setUserData(user))
    };
};

export default (withRouter(connect(null, mapDispatchToProps)(Login)));