import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Image, Menu, Dropdown, Button, } from 'semantic-ui-react';
import { createCustomerPortalSession } from '../api/payment';
import logo from './nano-logo.png';
import '../styles/header.css';
import '../styles/dashboardNav.css';
import mobile from './mobile_menu.png';

function Header(props) {
    const [activeItem, setActiveItem] = useState('');
    const [mobileMenu, setMobileMenu] = useState(false);

    if (props.location.pathname.includes('/admin')) return null;
    if (props.location.pathname.includes('/login')) return null;
    if (props.location.pathname.includes('/register')) return null;
    if (props.location.pathname.includes('/reset-password')) return null;
    console.log(props.status)

    return (
        <div>
            <Menu secondary size="huge">
                <Menu.Item header style={{marginLeft:"25px"}}>
                    <Image 
                        as={Link}
                        src={logo} 
                        size='tiny' 
                        width="252.1px" 
                        height="216.6px"
                        to="/"
                    />
                </Menu.Item> 
                <Menu.Item style={{marginRight:"50px"}} id="menuItem" position="right">
                    <div>
                    {props.status.user ? 
                        <Dropdown
                            text={props.status.user.email.toUpperCase()} 
                            style={{color:'#57FE76', fontWeight:"700", fontSize:"20px"}}
                        >   
                            <Dropdown.Menu direction="left" style={{marginTop:"20px"}}>
                                <Dropdown.Item onClick={createCustomerPortalSession}>
                                    Billing
                                </Dropdown.Item>   
                                <Dropdown.Item 
                                    as={ Link } 
                                    to='/logout'>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    :
                        <div>
                            <Button as={Link} to="/login">Login</Button>
                        </div>
                    }
                    </div> 
                </Menu.Item>

                <Menu.Item position="right" secondary id="side-drawer">
                <Dropdown
                        open = {mobileMenu} 
                        onClose={()=> setMobileMenu(false)}
                        icon={<Image 
                                src={mobile}
                                size="mini" 
                                alt="mobileMenu"
                                onClick={()=> setMobileMenu(true)}
                            />}
                    >
                        <Dropdown.Menu direction="left">
                            <Dropdown.Item onClick={createCustomerPortalSession}>
                                Billing
                            </Dropdown.Item>   
                            <Dropdown.Item 
                                as={ Link } 
                                to='/logout'>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>  
                </Menu.Item>                
            </Menu>
        </div>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};
    
export default (withRouter(connect(mapStateToProps)(Header)));
