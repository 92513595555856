import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import logo from '../nano-logo.png';
import mobileMenuPic from '../mobile_menu.png';
import '../../styles/header.css';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import { parseSrc } from '@lottiefiles/lottie-player';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

function CreateCoupon(props) {
    const [open, setModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [couponForm, setCouponForm] = useState({code: null, percentage: null, products: [], expiration: new Date(), maxUses: null});
   
    useEffect(()=> {
        getProducts().then(products => {
            setProducts(products)
        });
    }, []);
    console.log(couponForm)
    return (
        <Modal closeIcon onClose={()=> setModal(false)} open={open} size="small" trigger={
            <Button size="big" primary onClick={()=> setModal(true)}>
                Create Coupon
            </Button>
        }
    >
        <Modal.Header>Create New Coupon</Modal.Header>
        <Modal.Content>
            <Container textAlign="center">
                <Input style={{margin:"10px"}} fluid label="Coupon Code" onChange={(e)=> setCouponForm({...couponForm, code: e.target.value})}/>
                <Input style={{margin:"10px"}} fluid label="Percentage" onChange={(e)=> setCouponForm({...couponForm, percentage: parseInt(e.target.value)})}/>
                <Dropdown style={{margin:"10px"}} onChange={(e, {value})=> setCouponForm({...couponForm, products: value})} placeholder='Products' fluid multiple selection options={products.map(product => {
                        return { key: product.name, text: product.name, value: product }
                    })} 
                />
                <Calendar style={{margin:"10px"}} onChange={(date) => {
                        date = date.getTime() / 1000;
                        console.log(date)
                        setCouponForm({...couponForm, expiration: date})
                    }}/>
                <Input style={{margin:"10px"}} fluid label="Max Uses" onChange={(e)=> setCouponForm({...couponForm, maxUses: parseInt(e.target.value)})}/>
                <Button style={{margin:"10px"}} fluid onClick={()=> {
                    createCoupon(couponForm).then(()=> props.refreshCoupons())
                    setCouponForm({code: null, percentage: null, products: [], expiration: null, maxUses: null});
                    
                    return setModal(false);
                }}>
                    Create Coupon
                </Button>
            </Container>
        </Modal.Content>
    </Modal>
    );
};

const createCoupon = async (coupon) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/coupons/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            code: coupon.code,
            percentage: coupon.percentage, 
            products: coupon.products, 
            expiration: coupon.expiration, 
            maxUses: coupon.maxUses
        })
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return store.addNotification({
            title: "Success:",
            message: `Coupon successfully created.`,
            type: "success",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    } else {
        return store.addNotification({
            title: "Error:",
            message: JSON.stringify(response.error),
            type: "danger",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    }
};

export default CreateCoupon;

const getProducts = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/products', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};