import React, { useEffect, useState } from 'react';
import { Container, Table, Loader } from 'semantic-ui-react';
import RefreshProxyServer from '../../components/admin/Proxies/RefreshProxyServer';
import EditBlacklist from '../../components/admin/Proxies/EditBlacklistModal';
import BlacklistModal from '../../components/admin/Proxies/BlacklistsModal';
import { notify } from '../../utils/notification';

function Proxies(props) {
    const [proxyServers, setProxyServers] = useState(null);
    const [defaultBlacklist, setDefaultBlacklist] = useState({id: '', name: '', sites: []});

    useEffect(()=> {
        getProxyServers().then(servers => setProxyServers(servers));

        getDefaultBlacklist().then(data => {
            console.log(data)
            const defaultList = data.filter(list => list.name === 'default');
            if (defaultList.length === 0) return notify('danger', 'Error', 'Couldnt load default blacklist.');
            return setDefaultBlacklist({id: defaultList[0]._id, name: defaultList[0].name, sites: defaultList[0].sites});
        });
    }, []);

    return (
        <Container>
            <BlacklistModal defaultBlacklist={defaultBlacklist} refreshDefaultBlacklist={()=> {
                getDefaultBlacklist().then(data => {
                    console.log(data)
                    const defaultList = data.filter(list => list.name === 'default');
                    if (defaultList.length === 0) return notify('danger', 'Error', 'Couldnt load default blacklist.');
                    return setDefaultBlacklist({id: defaultList[0]._id, name: defaultList[0].name, sites: defaultList[0].sites});
                });
            }} />
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>IP Address</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {!proxyServers ? <Loader inverted size="large" active>Loading</Loader> : proxyServers.map((server)=> { 
                        return (
                            <Table.Row>                                       
                                <Table.Cell>{server.location}</Table.Cell>
                                <Table.Cell>{server.serverip}</Table.Cell>
                                <Table.Cell collapsing>
                                    <RefreshProxyServer ip={server.serverip} />
                                    <EditBlacklist ip={server.serverip} defaultBlacklist={defaultBlacklist.sites} />
                                </Table.Cell>
                            </Table.Row>
                            );                                 
                        })
                    }                    
                </Table.Body>
            </Table>
        </Container>
    )
};

export default Proxies;

const getProxyServers = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/proxies/server/list', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    
    return response.success;
};

const getDefaultBlacklist = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/proxies/blacklist/list', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    
    return response;
};
