import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { createCustomerPortalSession } from '../api/payment';
import { Container, Image, Menu, Dropdown, Button, Icon, Input, Divider } from 'semantic-ui-react';
import logo from './nano-logo.png';
import mobile from './mobile_menu.png';
import '../styles/header.css';

function Header(props) {
    const [activeItem, setActiveItem] = useState('');
    const [mobileMenu, setMobileMenu] = useState(false);

    if (props.location.pathname.includes('/dashboard')) return null;
    if (props.location.pathname.includes('/admin')) return null;
    if (props.location.pathname.includes('/login')) return null;
    if (props.location.pathname.includes('/register')) return null;
    if (props.location.pathname.includes('/reset-password')) return null;
    if (props.location.pathname.includes('/password')) return null;
    if (props.location.pathname.includes('/success')) return null;

    console.log(props.status.user)
    return (
        <div>
            <Menu secondary size="huge" >
                <Menu.Item header style={{marginLeft:"25px"}}>
                    <Image 
                        as={Link}
                        src={logo} 
                        style={{width: "100px", height: "100px"}}
                        width="252.1px" 
                        height="216.6px"
                        to="/"
                    />
                </Menu.Item>
            
                <Menu.Item position="right" secondary id="side-drawer">
                    <Dropdown
                        open = {mobileMenu} 
                        onClose={()=> setMobileMenu(false)}
                        icon={<Image 
                                src={mobile}
                                size="mini" 
                                alt="mobileMenu"
                                onClick={()=> setMobileMenu(true)}
                            />}
                    >
                        <Dropdown.Menu direction="left">
                            <Dropdown.Item 
                                as={ Link } 
                                to='/'>
                                Home
                            </Dropdown.Item>
                            <Dropdown.Item 
                                as={ Link } 
                                to='/pricing/datacenter'>
                                Proxies
                            </Dropdown.Item>
                            <Dropdown.Item 
                                as={ Link } 
                                to='/faq'>
                                FAQ
                            </Dropdown.Item>
                            <Dropdown.Item 
                                as={ Link } 
                                to='/contact'>
                                Contact
                            </Dropdown.Item>
                            {props.status.user ?
                                    <>
                                        <Dropdown.Item onClick={createCustomerPortalSession}>
                                            Billing
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/dashboard/proxies">
                                            Dashboard
                                        </Dropdown.Item>
                                        
                                        <Container style={{marginBottom:"10px"}}>
                                            <Divider />
                                            <Button as={Link} to="/logout">Logout</Button>
                                        </Container>
                                    </>
                                :
                                    <Container style={{marginBottom:"10px"}}>
                                        <Divider />
                                        <Button as={Link} to="/login">Login</Button>
                                        <Button as={Link} to="/register">Register</Button>
                                    </Container>
                                }
                        </Dropdown.Menu>
                    </Dropdown>   
                </Menu.Item>

                <Menu.Item position="right" style={{color:"#EEEEEE"}} id="menuItem" name='Home' active={activeItem === 'home'} onClick={(e, { name })=> setActiveItem(name)} to='/' /> 
                
                <Menu.Item id="menuItem" style={{color:"#EEEEEE"}} as={ Link } name='Proxies' to='/pricing/datacenter' active={activeItem === 'proxies'} />

                <Menu.Item id="menuItem" style={{color:"#EEEEEE"}} as={ Link } name='FAQ' active={activeItem === 'faq'} onClick={(e, { name })=> setActiveItem(name)} to='/faq'/>

                <Menu.Item 
                    style={{color:"#EEEEEE"}} 
                    as={ Link } 
                    name='Contact' 
                    active={activeItem === 'contact'} 
                    onClick={(e, { name })=> setActiveItem(name)}
                    to='/contact'
                    id="menuItem"
                />
                    
                <Menu.Item style={{marginRight:"50px"}} id="menuItem">
                    <div>
                    {props.status.user ? 
                        <Dropdown text={props.status.user.email.toUpperCase()} style={{color:'#57FE76', fontWeight:"700", fontSize:"20px"}}>   
                            <Dropdown.Menu direction="left" style={{marginTop:"20px"}}>
                                <Dropdown.Item 
                                    as={ Link } 
                                    to='/dashboard/proxies'>
                                    Dashboard
                                </Dropdown.Item>
                                <Dropdown.Item 
                                    as={ Link } 
                                    onClick={createCustomerPortalSession}
                                >
                                    Billing
                                </Dropdown.Item>
                                { props.status.user.isAdmin ? 
                                    <Dropdown.Item 
                                        as={ Link } 
                                        to='/admin'>
                                        Admin Panel
                                    </Dropdown.Item>
                                : null
                                }
                            
                                <Dropdown.Item 
                                    as={ Link } 
                                    to='/logout'>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    :
                        <div>
                        <Button as={Link} to="/login">Login</Button>
                        <Button as={Link} to="/register">Register</Button>
                        </div>
                    }
                    </div> 
                </Menu.Item>                         
            </Menu>
        </div>
    )
};
    
const mapStateToProps = state => {
    return { status: state.status }
};

export default (withRouter(connect(mapStateToProps)(Header)));
