import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Table, Button, Loader, Checkbox, Menu } from 'semantic-ui-react';
import DatacenterPackageEditModal from '../components/DatacenterPackageEditModal';
import BulkWhitelist from '../components/BulkWhitelist';
import { getOrderData } from '../api/user';
import { getDatacenterOrders, getDatacenterProducts } from '../api/proxies';

function DatacenterPortal(props) {
    const [proxyPackages, setProxyPackages] = useState([]);
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editList, setEditList] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(()=> {
        setLoading(true);

        getDatacenterOrders().then(data => {
            setLoading(false)
            
            return setProxyPackages(data.success)
        });
    }, []);
        console.log(proxyPackages)
        return (
            <Container>
                <Header style={{fontSize: "60px", marginBottom: "40px", color: "#57FE76", letterSpacing: '3px'}} textAlign="center">Proxy Manager</Header>
                <Loader active={loading} inverted />
                <Container textAlign="right">
                    <BulkWhitelist packages={editList} active={editList.length > 0 && proxyPackages.length !== 0} />
                </Container>
                {loading === false && proxyPackages.length === 0 ? 
                    <Container textAlign="center" style={{marginTop: "150px"}}>
                        <h1>Purchase a plan to get started </h1>
                        <Button style={{width: "300px"}} primary size="large" as={Link} to="/pricing/datacenter">
                            Datacenter Plans
                        </Button> 
                    </Container>
                :
                    
                    <Table selectable padded stackable singleLine inverted columns="4" style={{marginLeft:"auto", marginRight:"auto", marginTop:"20px", marginBottom:"30px", textAlign:"center"}}>
                        <Table.Row>
                            <Table.HeaderCell>Quantity</Table.HeaderCell>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Expires</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.HeaderCell>
                                <Checkbox disabled={loading} checked={editList.length === proxyPackages.length} onChange={()=> {
                                    if (editList.length === 0) {
                                        return setEditList(proxyPackages)
                                    } else if (proxyPackages.length === editList.length) {
                                        return setEditList([])
                                    } else if (editList.length > 0 && editList.length < proxyPackages.length) {
                                        const proxyTokensNotInEditList = proxyPackages.filter((pack)=> {
                                            if (!editList.includes(pack)) {
                                                return pack;
                                            } else return null;
                                        });
                                        return setEditList(editList.concat(proxyTokensNotInEditList))       
                                    };
                                }} />
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Body>
                            {proxyPackages.map((order)=> (
                                    <Table.Row>
                                        <Table.Cell style={{fontSize:"20px"}}>{order.proxies.length}</Table.Cell>
                                        <Table.Cell style={{fontSize:"20px"}}>{order.location || null}</Table.Cell>
                                        <Table.Cell>{new Date(order.expirationDate).toLocaleString()}</Table.Cell>
                                        <Table.Cell>
                                            <DatacenterPackageEditModal proxies={order.proxies} whitelistedIps={order.whitelistedIps} userIp={proxyPackages.userIp} token={order.proxyToken} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Checkbox checked={editList.includes(order)} name={order} onChange={(e, {name})=> {
                                                    const proxyTokenExistsAlready = editList.filter(token => token.proxyToken === order.proxyToken);
                                                    if (proxyTokenExistsAlready.length > 0) {
                                                        const removeProxyTokenFromEditList = editList.filter(token => token.proxyToken !== order.proxyToken);
                                                        return setEditList(removeProxyTokenFromEditList);
                                                    } else {
                                                        return setEditList([...editList, order])
                                                    }
                                                }} />
                                            </Table.Cell>
                                    </Table.Row> 
                                )
                            )}
                        </Table.Body>
                    </Table>
                }
            </Container>
        )
};

export default DatacenterPortal;

