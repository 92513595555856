import React, { Component } from 'react'
import { Container, Button, Header, Grid, Image, Divider } from 'semantic-ui-react'
import '../styles/Faq.css';

export default class Faq extends Component {
    render() {
        return (
            <Container textAlign="center">
                <Header className="title" size='huge'>Frequently Asked Questions</Header>
                <Divider />
                <div class="header">
                    <h1 class="faq-h1">Do you offer free replacements?</h1>
                    <p class="faq-p">Only within 24 hours of your purchase. Not valid if a release / drop happens within those 24 hours.</p>
                </div>
                <div class="header">
                    <h1 class="faq-h1">What websites do you guarantee?</h1>
                    <p class="faq-p">We guarantee no websites to stay unbanned.</p>
                </div>
                <div class="header">
                    <h1 class="faq-h1">My proxies are not working.</h1>
                    <p class="faq-p">Please confirm you have authenticated the correct IPv4 address in your portal, <br/> 
                    if they are still not working, please contact admins via Discord or email.</p>
                </div>
                <div class="header">
                    <h1 class="faq-h1">Can I use these proxies to farm one-clicks?</h1>
                    <p class="faq-p">Yes, farming one-clicks with any proxy package is okay unless proxy package <br/> description says otherwise.</p>
                </div>
                <div class="header">
                    <h1 class="faq-h1">Can I use these proxies to run for restocks?</h1>
                    <p class="faq-p">Yes, unless proxy package description says otherwise.</p>
                </div>
                <div class="header">
                    <h1 class="faq-h1">Is there a bandwidth cap on my proxy package?</h1>
                    <p class="faq-p">We offer unlimited bandwidth for datacenter pacakges.</p>
                </div>
            </Container>
        )
    }
};