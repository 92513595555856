import React, { useState, useEffect } from 'react'
import { Container, Modal, Button, Input, Loader } from 'semantic-ui-react';
import '.././styles/header.css';
import { notify } from '../utils/notification';
import { whitelistIp } from '../api/proxies';

export default function BulkWhitelist(props) {
    const [open, setModal] = useState(false);
    const [ip, setIp] = useState(null);
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        setPackages(props.packages)
    }, [props.packages]);

    async function bulkWhitelist(packages, newIp) {
        await packages.forEach(pack => whitelistIp(pack.proxyToken, [...pack.whitelistedIps, newIp]));
        setLoading(false);
        setModal(false);
        return notify('success', 'Success', `${newIp} is authenticated`);
    };
    
    return (
        <Modal size="small" closeIcon open={open} onClose={()=> setModal(false)} trigger={<Button disabled={!props.active} onClick={()=> setModal(true)}>Whitelist IP</Button>}>
            <Modal.Header>Whitelist IP for ({packages.length}) Packages: </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                            <Container textAlign="center">
                            <Loader active={loading} inverted />
                            <Input 
                                action={
                                    <Button onClick={()=> bulkWhitelist(packages, ip)}>
                                    Add IP
                                    </Button>
                                } 
                                placeholder='example: 171.22.139.114'
                                onChange={(e)=> setIp(e.target.value.toString())}
                            />
                            </Container>
                    </Modal.Description> 
                </Modal.Content>
        </Modal>
    );
};



