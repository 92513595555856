import React, { useEffect, useState } from 'react';
import { Container, Header, Button, Icon, Popup, Input, Modal, Label, Loader } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function BlacklistModal (props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newSiteInput, setNewSiteInput] = useState('');
    const [blacklist, setBlacklist] = useState(props.defaultBlacklist);
    console.log(props)

    useEffect(()=> {
        setBlacklist(props.defaultBlacklist)
    }, [props]);

    const removesite = async siteToDelete => {
        if (blacklist.sites.length === 1) return notify('warning', 'Error', 'There must be at least 1 site.');
        const updatedBlacklist = blacklist.sites.filter(site => site !== siteToDelete);
        
        return setBlacklist({...blacklist, sites: updatedBlacklist});
    };

    const addSite = async siteToAdd => {
        return setBlacklist({...blacklist, sites: [...blacklist.sites, siteToAdd]});
    };
    
    return (
        <Modal open={open} onClose={()=> setOpen(false)} trigger={<Button style={{margin:"10px"}} color="blue" name='Default Blacklist' link onClick={()=> {
                setOpen(true)
                setLoading(true)
                setLoading(false)
            }} >Default Blacklist</Button>}>
            <Modal.Header>Configure Default Blacklist</Modal.Header>
                {loading ? null : <Modal.Content scrolling>
                    <Header>Blacklisted Sites</Header>
                    <Container>
                        {blacklist.sites.length === 0 ? <Loader active={loading} /> : 
                            <>
                            {blacklist.sites.map(site => {
                                return (
                                    <Label size="large" style={{margin:"15px", verticalAlign:"middle"}}>
                                        {site} <Icon color="red" name="delete" onClick={()=> removesite(site)}/>
                                    </Label>
                                )
                            })}
                        
                        
                            <Popup content='Add site to blacklist' trigger={
                                    <Modal size="tiny" trigger={<Button style={{verticalAlign:"middle"}} primary icon={"add"} />}>
                                        <Modal.Header>Add Site</Modal.Header>
                                        <Modal.Content>
                                            <Input 
                                                fluid 
                                                action={
                                                    <Button onClick={()=> addSite(newSiteInput)}>
                                                    Add Site
                                                    </Button>}  
                                                placeholder='ex: .supremenewyork.com'
                                                onChange={(e) => setNewSiteInput(e.target.value)}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                }
                            />
                            </>
                        }
                    </Container>        
                </Modal.Content>}
            <Modal.Actions>
            <div style={{textAlign:"center"}}>
                <Button style={{margin:"15px"}} onClick={()=> setOpen(false)} color='red'>
                    <Icon name='remove' /> Cancel Edits
                </Button>
                <Button style={{margin:"15px"}} color='green' onClick={()=> {
                        editBlacklist(blacklist).then(()=> {
                            setOpen(false);
                            return props.refreshDefaultBlacklist();
                        })
                    }}>
                    <Icon name='checkmark' /> Save Configuration
                </Button>
            </div>
            </Modal.Actions>
        </Modal>
    )
};

export default BlacklistModal;



const editBlacklist = async (blacklist) => {
    
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/blacklist/edit/${blacklist.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(blacklist)
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    
    return response;
};