import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown, Grid } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import '../../styles/header.css';
import CreateProxyPackage from '../admin/CreateProxyPackage';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

function EditProduct(props) {
    const [open, setModal] = useState(false);
    const [productForm, setProductForm] = useState(props.product);
    const [addFieldModal, setAddFieldModal] = useState(false);
    const [fieldOptions, setFieldOptions] = useState({ quantity: false, location: false, imageUrl: false });

    console.log(productForm)
    return (
        <Modal size="large" closeIcon open={open} onClose={()=> setModal(false)} trigger={<Button onClick={()=> setModal(true)} icon="edit" />}>
            <Modal.Header>Edit Product:</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                            <Container>
                                <Grid>
                                    <Grid.Column width={8}>
                                        {
                                            Object.entries(productForm).map((property)=> {
                                                if (property[0] === '_id') return null;
                                                if (property[0] === '__v') return null;
                                                if (property[0] === 'date') return null;
                                                
                                                return(
                                                    
                                                    <Input defaultValue={property[1]} style={{margin: "10px"}} label={property[0]} onChange={(e)=> {
                                                            setProductForm({...productForm, [property[0]]: (!isNaN(e.target.value) ? parseFloat(e.target.value) : e.target.value) })
                                                        }} 
                                                    />
                                                )
                                            }) 
                                        }
                                        {fieldOptions.location ? <Input label="Location" onChange={(e)=> setProductForm({...productForm, location: e.target.value})}/> : null}<br/>
                                        {fieldOptions.quantity ? <Input label="Quantity" onChange={(e)=> setProductForm({...productForm, quantity: parseInt(e.target.value)})}/> : null}<br/>
                                        {fieldOptions.imageUrl ? <Input label="Image URL" onLoad={(e)=> {
                                               return setProductForm({...productForm, imageUrl: ''})
                                                //setFieldOptions({...fieldOptions, imageUrl: false})
                                            }}/> : null}<br/>
                                        <Modal closeIcon onClose={()=> setAddFieldModal(false)} open={addFieldModal} size="tiny" trigger={
                                                <Button onClick={()=> setAddFieldModal(true)} style={{margin:"10px"}} icon={"add"}></Button>
                                            }
                                        >
                                            <Modal.Header>Add Field</Modal.Header>
                                            <Modal.Content>
                                                <Button style={{margin:"10px"}} onClick={()=> {setProductForm({...productForm, location: ''}); setAddFieldModal(false);}}>Location</Button>
                                                <Button style={{margin:"10px"}} onClick={()=> {setProductForm({...productForm, quantity: ''}); setAddFieldModal(false);}}>Quantity</Button>
                                                <Button style={{margin:"10px"}} onClick={()=> {setProductForm({...productForm, imageUrl: ''}); setAddFieldModal(false);}}>Image</Button>
                                                <Button style={{margin:"10px"}} onClick={()=> {setProductForm({...productForm, available: false}); setAddFieldModal(false);}}>Available</Button>
                                                <Button style={{margin:"10px"}} onClick={()=> {setProductForm({...productForm, minPrice: .50}); setAddFieldModal(false);}}>Minimum Price</Button>
                                            </Modal.Content>
                                        </Modal>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <pre>{JSON.stringify(productForm, null, 2)}</pre>
                                    </Grid.Column>
                                </Grid>
                                <Button onClick={()=> {
                                    editProduct(productForm).then(()=> props.refreshProducts())
                                    setModal(false)
                                    setFieldOptions({ quantity: false, location: false, imageUrl: false })
                                    }} style={{marginTop:"25px"}} fluid>Save Changes</Button>
                            </Container>
                    </Modal.Description> 
                </Modal.Content>
        </Modal>
    );
};

const editProduct = async (product) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/products/edit/${product._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            id: product._id,
            product: product
        })
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return store.addNotification({
            title: "Success:",
            message: `Product successfully edited.`,
            type: "success",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    } else {
        return store.addNotification({
            title: "Error:",
            message: JSON.stringify(response.error),
            type: "danger",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    }
};

export default EditProduct;