import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown } from 'semantic-ui-react';

import '../../styles/header.css';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

export default function AssignCredits(props) {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(props.user);

    return (
        <Modal size="mini" onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={<Button>Assign Credits</Button>}>
            <Modal.Content>
                <Input label="Credits" onChange={(e)=> {
                    console.log(parseInt(e.target.value))
                    return setUser({...user, credits: parseInt(e.target.value)})
                }}/>
                
                <Button style={{marginTop: "20px"}} fluid onClick={()=> {
                    assignCredits(user).then(()=> props.refreshUsers())
                    return setOpen(false);
                }}>Assign Credits</Button>
            </Modal.Content>
        </Modal>
    );
};

const assignCredits = async (user) => {
    console.log(user)
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/users/edit/${user._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            user: user
        })
    }).then(response => {
        return response.json();
    });
    return response;
};