import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Modal, Header, Portal, Segment, Form, Button, Icon, Input, Divider, Confirm, Dropdown } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import '../../styles/header.css';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import { Provider } from 'react-redux';

function CreateProduct(props) {
    const [open, setModal] = useState(false);
    const [addFieldModal, setAddFieldModal] = useState(false);
    const [location, showLocation] = useState(false);
    const [quantity, showQuantity] = useState(false);
    const [minPrice, showMinPrice] = useState(false);
    const [productForm, setProductForm] = useState({name: null, category: null, price: null, location: null, quantity: null});
    console.log(productForm)
    return (
        <Modal closeIcon onClose={()=> {
            setModal(false);
            showLocation(false);
        }} open={open} size="small" trigger={
            <Button size="big" primary onClick={()=> setModal(true)}>
                Create Product
            </Button>
        }
    >
        <Modal.Header>Create New Product</Modal.Header>
        <Modal.Content>
            <Container textAlign="center">
                <Input fluid  label="Name" onChange={(e)=> setProductForm({...productForm, name: e.target.value})}/> <br/>
                <Dropdown fluid label="Category" clearable placeholder='Category' selection options={categoryOptions} onChange={(e, {value})=> setProductForm({...productForm, category: value})} /><br/>
                <Input fluid  label="Price" onChange={(e)=> setProductForm({...productForm, price: parseFloat(e.target.value)})}/><br/>
                {location ? <Input fluid  label="Location" onChange={(e)=> setProductForm({...productForm, location: e.target.value})}/> : null}<br/>
                {quantity ? <Input fluid  label="Quantity" onChange={(e)=> setProductForm({...productForm, quantity: parseInt(e.target.value)})}/> : null}<br/>
                {minPrice ? <Input fluid  label="minPrice" onChange={(e)=> setProductForm({...productForm, minPrice: parseInt(e.target.value)})}/> : null}<br/>
                <Input fluid  label="Image URL" onChange={(e)=> setProductForm({...productForm, image: e.target.value})}/> <br/>
                <Modal closeIcon onClose={()=> setAddFieldModal(false)} open={addFieldModal} size="tiny" trigger={
                        <Button onClick={()=> setAddFieldModal(true)} style={{margin:"10px"}} icon={"add"}></Button>
                    }
                >
                    <Modal.Header>Add Field</Modal.Header>
                    <Modal.Content>
                        <Button style={{margin:"10px"}} onClick={()=> {showLocation(true); setAddFieldModal(false);}}>Location</Button>
                        <Button style={{margin:"10px"}} onClick={()=> {showQuantity(true); setAddFieldModal(false);}}>Quantity</Button>
                        <Button style={{margin:"10px"}} onClick={()=> {showMinPrice(true); setAddFieldModal(false);}}>Minimum Price</Button>
                    </Modal.Content>
                </Modal>
                <Button style={{margin:"10px"}} fluid onClick={()=> {
                    createProduct(productForm);
                    setProductForm({name: null, category: null, price: null});
                    showLocation(false)
                    showQuantity(false)
                    return setModal(false);
                }}>
                    Create Product
                </Button>
            </Container>
        </Modal.Content>
    </Modal>
    );
};

const createProduct = async (product) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/products/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            name: product.name,
            category: product.category, 
            price: product.price, 
            quantity: product.quantity, 
            location: product.location
        })
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return store.addNotification({
            title: "Success:",
            message: `Product successfully created.`,
            type: "success",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    } else {
        return store.addNotification({
            title: "Error:",
            message: JSON.stringify(response.error),
            type: "danger",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        })
    }
};

export default CreateProduct;

const categoryOptions = [
    { key: 'datacenter', text: 'Datacenter', value: 'datacenter' }
];