import React, { useEffect, useState } from 'react'
import { Container, Table,  Search } from 'semantic-ui-react';
import EditUser from '../../components/admin/EditUser';
import { formatDate } from '../../utils/formatDate';

function Users(props) {
    const [users, setUsers] = useState(null);
    const [searchBar, setSearchBar] = useState('');
    useEffect(()=> {
        getAllUsers().then(users => setUsers(users));
    }, []);

    return (
        <Container>
            <Search placeholder="Search for a user..." onSearchChange={(e) => setSearchBar(e.target.value)}/>
                <Table celled inverted>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Stripe ID</Table.HeaderCell>
                            <Table.HeaderCell>Total Orders</Table.HeaderCell>
                            <Table.HeaderCell>Last Active</Table.HeaderCell>
                            <Table.HeaderCell>Edit</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!users ? null : users.filter(user => user.email.includes(searchBar)).map((user)=> { 
                            return (
                                <Table.Row>                                       
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.stripeId}</Table.Cell>
                                    <Table.Cell>{user.orders.length}</Table.Cell>
                                    <Table.Cell>{user.hasOwnProperty('lastActive') ? formatDate(user.lastActive) : 'Inactive'}</Table.Cell>
                                    <Table.Cell collapsing>
                                        <EditUser user={user} refreshUsers={()=> getAllUsers().then(users => setUsers(users))} />
                                        {/* <Button icon="close icon" onClick={()=>{this.deleteUser(user.discordId)}}/>                                */}
                                    </Table.Cell>
                                </Table.Row>
                                );                                 
                            })
                        }                    
                    </Table.Body>
                </Table>
        </Container>
    )
};

export default Users;

const getAllUsers = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/users', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    console.log(response)
    return response;
};